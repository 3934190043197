import React, { useRef, useEffect } from "react";

/**
 * Represents a drawing canvas that creates trails as the user moves the mouse.
 *
 * @component
 */
const Draw = () => {
  const canvasRef = useRef(null);
  const lastPositionRef = useRef(null);
  const fadeOutTimerRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const handleMouseMove = (e) => {
      // Clear the fade-out timer
      if (fadeOutTimerRef.current) {
        clearTimeout(fadeOutTimerRef.current);
      }

      // Set the drawing settings
      ctx.lineWidth = 0.2;
      ctx.lineCap = "round";
      ctx.strokeStyle = "#fff";
      ctx.globalAlpha = 1;

      const { pageX, pageY } = e;

      if (lastPositionRef.current) {
        const { x, y } = lastPositionRef.current;
        ctx.moveTo(x - canvas.offsetLeft, y - canvas.offsetTop);
        ctx.lineTo(pageX - canvas.offsetLeft, pageY - canvas.offsetTop);
        ctx.stroke();
      }

      // Update the last position
      lastPositionRef.current = { x: pageX, y: pageY };

      // Start a new fade-out timer
      fadeOutTimerRef.current = setTimeout(() => {
        // Gradually fade out the lines
        const fadeInterval = setInterval(() => {
          ctx.fillStyle = "rgba(0, 0, 0, 0.1)";
          ctx.fillRect(0, 0, canvas.width, canvas.height);

          if (ctx.globalAlpha <= 0.1) {
            clearInterval(fadeInterval);
            // Clear the canvas completely
            ctx.clearRect(0, 0, canvas.width, canvas.height);
          }
        }, 50);
      }, 2000); // Trigger after 2 seconds of inactivity
    };

    canvas.addEventListener("mousemove", handleMouseMove);

    return () => {
      canvas.removeEventListener("mousemove", handleMouseMove);
      if (fadeOutTimerRef.current) {
        clearTimeout(fadeOutTimerRef.current);
      }
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{ position: "absolute", top: 100, left: 0 }}
      width={window.innerWidth}
      height={window.innerHeight - 100}
    />
  );
};

export default Draw;
